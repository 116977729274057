:root {
  --primary-color: #2F714D;
  --background-color: #F5F9F6;
  --custom-ligth-gray: #efefef;
  --custom-gray: #cecece;
  --custom-dark-gray: #565656;
  --custom-dark-translucent-gray: #56565644;
  --menu-color: #343A40;
}

body {
  background-color: #F5F9F6;
  font: normal 0.9em/1.5 Arial,Helvetica,sans-serif;
  max-width: none !important;
}

.test-logo-container {
  position: absolute;
  top: 100px;
  right: 100px;
}

.test-logo {
  width: 150px;
}

.watermark-container {
  position: fixed;
  top: 30px;
  right: 50px;
}

.watermark {
  font-size: 4rem;
  font-weight: 900;
  color: var(--custom-dark-translucent-gray);
}

.mobile-view {
  display: none;
}

.pdf-icon-container {
  color: red;
  text-align: center;
  font-size: x-large;
}

@media (max-width:768px) {

  .watermark-container {
    position: fixed;
    top: 40px;
    right: 10px;
  }

  .watermark {
    font-size: 2rem;
    font-weight: 900;
    color: var(--custom-dark-translucent-gray);
  }

  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .card-fields-container {
      display: flex;
      flex-direction: column;
  }

  .card-field-container {
      display: flex;
      font-size: 0.7rem;
  }

  .card-field-title {
      font-weight: bold;
      margin-right: 5px;
  }

  .card-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .document-head-container {
    display: flex;
    justify-content: space-between;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    overflow-y: scroll;
    height: 100vh;
  }

  .cards-container a {
      display: flex;
      font-weight: normal;
      text-decoration: none;
      color: black;
      font-size: 0.7rem;
      width: 100%;
      text-align: center;
      justify-content: center;
  }

  .pdf-icon-container {
    font-size: 1rem;
  }
}