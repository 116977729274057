.uploaddocs-datefields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-top: 20px;
}

.textfield-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 270px;
}

#upload-docs-form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    /*position: relative;*/
    margin: auto;
  }
  
  #upload-docs-input-file-upload {
    display: none;
  }
  
  #upload-docs-label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }
  
  .upload-docs-upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-docs-upload-button:hover {
    text-decoration-line: underline;
  }

  #upload-docs-label-file-upload.drag-active {
    background-color: #ffffff;
  }

  #upload-docs-drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .file-state-icon-container-xmark{
    fill: red;
    font-size: 20px;
  }
  
  .file-state-icon-container-xmark svg{
    fill: red;
  }

  .file-state-icon-container-upload {
    color: #00ACE6;
    font-size: 20px;
  }

  .file-state-icon-container-uncheck {
    color: green;
    font-size: 20px;
  }

  .upload-docs-used-storage-container {
    width: 300px;
    height: 90px;
    border: 2px solid #cbd5e1;
    border-radius: 1rem;
    padding: 10px;
  }