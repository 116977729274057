body {
    background-color: #F5F9F6 !important;
    font: normal 0.9em/1.5 Arial,Helvetica,sans-serif;
    max-width: none !important;
}

.text-mobile {
    display: none;
}

.burger-button-container {
    display: none;
}

.menu-logo-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 30px;
}

.menu-logo{
    width: 150px;
}

.menu{
    display: flex;
    flex-direction: column;
    width: 70px;
    height: 100%;
    background-color: #343A40;
    color: white;
    position: fixed;
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    z-index: 1000;
}

.menu-active{
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 100%;
    background-color: #343A40;
    color: white;
    position: fixed;
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    z-index: 1000;
}

.home-layout-active-logo-container {
    position: absolute;
    bottom: 40px;
    text-align: center;
    width: 100%;
}

.menu-title{
    font-weight: 900;
    font-size: 14px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.menu ul {
    list-style-type: none;
}

.menu li{
    font-size: 14px;
    padding-bottom: 10px;
    text-decoration: none;
}

.menu-icon-container {
    min-width: 50px;
    /*border: 1px solid white;*/
}

.menu-icon{
    font-size: 30px;
}

.menu-option-text-active{
    margin-left: 30px;
    /*border: 1px solid white;*/
    min-width: 600px;
    height: 20px;
    /*margin-top: 30px;*/
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 1;
    transition: transfom opacity;
}

.menu-option-text{
    margin-left: 30px;
    /*border: 1px solid white;*/
    min-width: 0px;
    width: 0px;
    height: 20px;
    overflow: hidden;
    /*margin-top: 30px;*/
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 0;
    transition: transfom opacity;
}

.menu-option-text-hover{
    margin-top: 20px;
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 1;
    transition: transfom opacity 
}

.menu-option-wrapper{
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    align-items: center;
    margin-bottom: 35px;
    margin-left: 22px;
}

.menu-option-link-container {
    display: flex;
    align-items: center;
}

.menu-option-link:hover {
    text-decoration: none;
}

.home-wrapper{
    /*padding-left: 70px;*/
    left: 0px;
}

.home-logo-container {
    padding-top: 60px;
    padding-left: 110px;
}

.home-logo {
    width: 200px;
    /*background-color: var(--primary-color);*/
    padding: 20px;
    border-radius: 5px;
}

.home-main {
    padding-top: 20px;
    padding-left: 110px;
    padding-right: 20px;
}

.info-table {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    overflow: hidden;
}

.info-table th{
    /*background-color: #1386B3;*/
    background-color: var(--primary-color);
    color: white;
    padding-left: 10px;
    border-bottom: 1px solid #dee2e6;
}

.info-table td{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid #dee2e6;
}

.table-title {
    font-weight: bold;
}

.info-title {
    font-weight: bold;
}

.page-container {
    font-size: 0.95em;
    background-color: white;
    padding: 50px;
}

.page-body {
    padding: 20px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
}

.doc-table td {
    height: 50px;
}

.legend-container {
    display: flex;
    position: fixed;
    right: -350px;
    top: 200px;
    transform:translate(0px);
    transition: right 500ms ease-in-out;
    z-index: 1000;
}

.legend-container-active {
    display: flex;
    position: fixed;
    right: 0px;
    top: 200px;
    transform:translate(0px);
    transition: right 500ms ease-in-out;
    z-index: 1000;
}

.legend-button {
    display: flex;
    background-color: #343A40;
    width: 50px;
    height: 40px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: -5px;
    border: none;
}

.legend-window {
    background-color: #343A40;
    padding: 20px;
    color: white;
    border-radius: 5px;
    width: 350px;
    font-size: 12px;
}

.tipo-item {
    color: white;
    font-size: 12px;
}

.home-layout-active-logo {
    width: 60px;
}

@media (max-width:768px) {
    .menu{
        left: -100%;
        width: 80%;
        max-width: 600px;
        height: 100%;
        background-color: #343A40;
        color: white;
        position: fixed;
        transform:translate(0px);
        transition: all 500ms ease-in-out;
    }

    .menu-active{
        left: 0;
        width: 80%;
        max-width: 350px;
        height: 100%;
        background-color: #343A40;
        color: white;
        position: fixed;
        transform:translate(0px);
        transition: all 500ms ease-in-out;
    }

    .menu-option-text{
        margin-left: 30px;
        /*border: 1px solid white;*/
        min-width: 0;
        width: 50%;
        height: 100%;
        /*margin-top: 30px;*/
        transform:translate(0px);
        transition: all 500ms ease-in-out;
        opacity: 1;
        transition: transfom opacity;
        overflow: visible;
    }

    .menu-option-text-active{
        margin-left: 30px;
        /*border: 1px solid white;*/
        min-width: 0;
        width: 50%;
        height: 100%;
        /*margin-top: 30px;*/
        transform:translate(0px);
        transition: all 500ms ease-in-out;
        opacity: 1;
        transition: transfom opacity;
        overflow: visible;
    }

    .menu-option-wrapper{
        display: flex;
        flex-direction: row;
        /*justify-content: center;*/
        align-items: center;
        margin-bottom: 35px;
        margin-left: 22px;
    }

    .brand-title {
        display: none;
    }

    .nav-username {
        display: none;
    }

    .burger-button svg{
        fill: white;
    }

    .burger-button-container {
        display: block;
        width: 100%;
        text-align: right;
    }

    .burger-button {
        display: inline-block;
        color: white;
        width: 20px;
        height: 20px;
    }

    .home-logo {
        width: 150px;
        /*background-color: var(--primary-color);*/
        border-radius: 5px;
    }

    .home-logo-container {
        padding-top: 40px;
        padding-left: 0;
    }

    .legend-window {
        width: 200px;
    }

    .legend-container {
        display: none;
        right: -200px;
    }
    
    .legend-container-active {
        right: 0px;
    }

    .home-main {
        padding-top: 0;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }

    .page-container {
        font-size: 0.95em;
        background-color: white;
        padding: 10px;
        width: 100%;
    }

    .page-body {
        padding: 0;
    }

    .text-desktop {
        display: none;
    }

    .text-mobile {
        display: block;
    }
}