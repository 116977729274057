.admin-layout-container {
    min-width: 800px;
}

.admin-logo-container {
    padding-top: 60px;
    padding-left: 110px;
}

.admin-logo {
    width: 200px;
    /*background-color: var(--primary-color);*/
    padding: 20px;
    border-radius: 5px;
}

.admin-layout-desktop-view {
    display: inline;
}

.admin-layout-mobile-view {
    display: none;
}

.admin-warning-container {
    padding-left: 110px;
    padding-right: 20px;
}

@media (max-width:768px) {
    .admin-layout-desktop-view {
        display: none;
    }
    
    .admin-layout-mobile-view {
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;
    }
}

